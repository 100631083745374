@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-success: #1BA418;
    --color-warning: #D89C1E;
    --color-error: #BF2912;
    --color-disabled: #B9C0D4;
    --color-disabled-text: #98A1BE;
    --color-light-text: #5A697B;
    --color-dark-text: #2B303C;
    --color-brand: #1B6EBB;
    --color-brand-ultralight: #7AB9F4;
    --color-brand-light: #3997EF;
    --color-brand-medium: #124C81;
    --color-brand-dark: #0D2D49;
    --color-gray-203: #7296B8;
    --color-gray-204: #C8DAEB;
    --color-gray-205: #EDEEEF;
    --color-gray-206: #F1F4F9;
    --color-gray-207: #F7F8FA;
    --color-gray-208: #FDFDFF;
    --color-blue-301: #033B69;
    --color-blue-302: #316797;
    --color-blue-303: #538DD3;
    --color-blue-304: #93CBFE;
    --color-blue-305: #BCDFFD;
    --color-blue-306: #E0EDFB;
    --color-blue-307: #EBF3FA;
    --color-blue-308: #00142566;
    --color-blue-309: #001425;
    --color-compliant-green: #105F0E;
    --color-compliant-orange: #AB4205;
    --color-compliant-red: #9D2210;
    --color-red-50: #FFF6F5;
    --color-red-100: #FCEBE8;
    --color-red-200: #FACDC7;
    --color-red-300: #F6AFA4;
    --color-red-400: #F1806F;
    --color-red-500: #ED604B;
    --color-red-700: #BF2912;
    --color-red-900: #69190C;
    --color-orange-50: #FFF9F5;
    --color-orange-100: #FDEEE2;
    --color-orange-200: #FDD1AE;
    --color-orange-300: #FCB071;
    --color-orange-400: #FB882A;
    --color-orange-500: #E76805;
    --color-orange-700: #9B3E08;
    --color-orange-900: #592B03;
    --color-honey-50: #FFFCF5;
    --color-honey-100: #FFF1D1;
    --color-honey-200: #FAD97F;
    --color-honey-300: #EDC135;
    --color-honey-400: #D89C1E;
    --color-honey-500: #C77D14;
    --color-honey-700: #80450E;
    --color-honey-900: #502A0C;
    --color-lime-50: #F9FFF0;
    --color-lime-100: #ECFAD1;
    --color-lime-200: #CDEE86;
    --color-lime-300: #A2E538;
    --color-lime-400: #76CE12;
    --color-lime-500: #5AB60C;
    --color-lime-700: #2D7207;
    --color-lime-900: #234903;
    --color-green-50: #F5FFFA;
    --color-green-100: #E0FBEC;
    --color-green-200: #B1F1D0;
    --color-green-300: #A2E538;
    --color-green-400: #1EE67D;
    --color-green-500: #1DC36D;
    --color-green-700: #2D7207;
    --color-green-900: #234903;
    --color-teal-50: #F5FEFC;
    --color-teal-100: #DAFBF3;
    --color-teal-200: #99F6E0;
    --color-teal-300: #5FE9D0;
    --color-teal-400: #2ED3B7;
    --color-teal-500: #15B79E;
    --color-teal-700: #107569;
    --color-teal-900: #134E48;
    --color-blue-light-50: #F5FBFF;
    --color-blue-light-100: #E7F5FE;
    --color-blue-light-200: #B9E6FE;
    --color-blue-light-300: #7CD4FD;
    --color-blue-light-400: #36BFFA;
    --color-blue-light-500: #0BA5EC;
    --color-blue-light-700: #026AA2;
    --color-blue-light-900: #0B4A6F;
    --color-blue-brand-50: #FAFDFF;
    --color-blue-brand-100: #EAF3FD;
    --color-blue-brand-200: #C4DFF8;
    --color-blue-brand-300: #9ECBF5;
    --color-blue-brand-400: #2887E0;
    --color-blue-brand-500: #1B6EBB;
    --color-blue-brand-700: #134D84;
    --color-blue-brand-900: #0F3D69;
    --color-indigo-50: #FBFAFF;
    --color-indigo-100: #F2F0FF;
    --color-indigo-200: #DBD7F9;
    --color-indigo-300: #C1BAF5;
    --color-indigo-400: #9D91F2;
    --color-indigo-500: #8578ED;
    --color-indigo-700: #4D3AE4;
    --color-indigo-900: #3021A1;
    --color-purple-50: #FDFAFF;
    --color-purple-100: #F6EDFC;
    --color-purple-200: #E9D0F8;
    --color-purple-300: #DBB1F4;
    --color-purple-400: #C57CF4;
    --color-purple-500: #B75FEC;
    --color-purple-700: #8D1ED2;
    --color-purple-900: #571281;
    --color-magenta-50: #FFFAFE;
    --color-magenta-100: #FCE9F7;
    --color-magenta-200: #FCCAEE;
    --color-magenta-300: #FAA5E2;
    --color-magenta-400: #F66FCD;
    --color-magenta-500: #E750BC;
    --color-magenta-700: #B71588;
    --color-magenta-900: #7D075C;
    --color-gray-cool-50: #F9F9FB;
    --color-gray-cool-100: #EFF1F5;
    --color-gray-cool-200: #DCDFEA;
    --color-gray-cool-300: #B9C0D4;
    --color-gray-cool-400: #7D89B0;
    --color-gray-cool-500: #5D6B98;
    --color-gray-cool-700: #404968;
    --color-gray-cool-900: #111322;
    --color-gray-neutral-50: #F9FAFB;
    --color-gray-neutral-100: #F3F4F6;
    --color-gray-neutral-150: #EBEBEB;
    --color-gray-neutral-200: #E5E7EB;
    --color-gray-neutral-300: #D2D6DB;
    --color-gray-neutral-400: #9DA4AE;
    --color-gray-neutral-500: #6C737F;
    --color-gray-neutral-700: #384250;
    --color-gray-neutral-900: #111927;
  }
}

.floating {
  animation-name: floating;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

.floating-reverse {
  animation-name: floating;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
  margin-left: 30px;
  margin-top: 5px;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  25% {
    transform: translate(0, 45px) rotateZ(-20deg);
  }
  50% {
    transform: translate(20px, -25px) rotateZ(65deg);
  }
  75% {
    transform: translate(0, 25px) rotateZ(10deg);
  }
  100% {
    transform: translate(0, 0) rotateZ(0deg);
  }
}
